import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { PricePerUnit, RawPrice } from "@vp/vp-tokenized-fragment";
import { isVatInclusive } from "@internal/data-access-pricing";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";

export interface UnitPricing {
    listPrice: number;
    discountPrice: number;
    unitListPrice: number;
    unitDiscountedPrice: number;
    currency: string;
}

export interface UnitPricingProps {
    pricing: UnitPricing;
    className?: string;
    messages: {
        priceLabel: { id: string };
        pricePerUnitLabel: { id: string };
    };
}

export const UnitPricingDisplay = observer((props: UnitPricingProps) => {
    const { pricing, className, messages } = props;
    const { locale } = useProductAndProjectStateManager().data;
    const vatInc = isVatInclusive();
    const { t } = useTranslationSSR();

    return (
        <div className={className}>
            <RawPrice
                placeHolderText={t(messages.priceLabel.id)}
                pricingInfo={{
                    listPrice: pricing.listPrice,
                    discountPrice: pricing.discountPrice,
                    culture: locale,
                    currency: pricing.currency,
                    minimumFractionDigits: 2,
                    vatInc,
                    hideVatMessage: false
                }}
            />{" "}
            (
            <PricePerUnit
                placeHolderText={t(messages.pricePerUnitLabel.id)}
                pricingInfo={{
                    culture: locale,
                    currency: pricing.currency,
                    minimumFractionDigits: 2,
                    unitListPrice: pricing.unitListPrice,
                    unitDiscountPrice: pricing.unitDiscountedPrice,
                    vatInc,
                    hideVatMessage: true
                }}
            />
            )
        </div>
    );
});

UnitPricingDisplay.displayName = "UnitPricingDisplay";
