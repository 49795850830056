import React, { createContext, useMemo } from "react";
import { LocalizedKeys } from "./localizations";

export const LocalizedContext = createContext<
    | {
          get(key: keyof LocalizedKeys, replaceValues?: Record<string, string>): string;
          locale: string;
      }
    | undefined
>(undefined);

export interface LocalizedProviderProps {
    children?: React.ReactNode | React.ReactNodeArray;
    /**
     * The localized keys provided by the host application
     */
    localizedValues: LocalizedKeys;
    locale: string;
}

/**
 * @param {LocalizedProviderProps} props
 * @returns JSX.Element
 */
export const LocalizedProvider = (props: LocalizedProviderProps) => {
    const { children, localizedValues, locale } = props;
    const context = useMemo(() => {
        return {
            get(key: keyof LocalizedKeys, replaceValues?: Record<string, string>) {
                let localizedString = localizedValues[key];

                for (const replaceKey in replaceValues) {
                    if (!Object.prototype.hasOwnProperty.call(replaceValues, replaceKey)) {
                        continue;
                    }
                    localizedString = localizedString.replaceAll(`[[${replaceKey}]]`, replaceValues[replaceKey]);
                }

                return localizedString;
            },
            locale
        };
    }, [localizedValues, locale]);

    return <LocalizedContext.Provider value={context}>{children}</LocalizedContext.Provider>;
};
