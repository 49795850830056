import React from "react";
import { PlusOrMinus, VatMessage } from "@vp/vp-tokenized-fragment";
import { P, type PProps } from "@vp/swan";
import { isVatInclusive } from "@internal/data-access-pricing";
import classNames from "classnames";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import { useLocalized } from "./localized";

export interface DifferentialPricing {
    listPrice?: string | number;
    discountPrice: number;
}

export interface DifferentialPricingProps extends PProps {
    pricing?: DifferentialPricing;
    hideQuantity?: boolean;
    hideVatMessage?: boolean;
    negatePricingValues?: boolean;
    placeHolderText?: string;
    className?: string;
    priceIncludedClassname?: string;
    currency: string;
}

export const DifferentialPricingDisplay = observer((props: DifferentialPricingProps) => {
    const {
        pricing,
        hideQuantity,
        hideVatMessage,
        negatePricingValues,
        placeHolderText = "...",
        className,
        priceIncludedClassname,
        currency,
        ...rest
    } = props;
    const { locale, quantity } = useProductAndProjectStateManager().data;
    const localized = useLocalized();

    if (!pricing || typeof pricing.listPrice === "undefined") {
        return null;
    }
    if (pricing.listPrice === "Included" || pricing.listPrice === 0) {
        return <span className={classNames(className, priceIncludedClassname)}>{localized.get("included")}</span>;
    }
    const vatInc = isVatInclusive();
    let { listPrice, discountPrice } = pricing;
    if (negatePricingValues) {
        listPrice = -1 * (listPrice as number);
        discountPrice *= -1;
    }

    return (
        <P data-testid="differential-pricing-show-quantity" className={className} fontSkin="body-standard" {...rest}>
            <PlusOrMinus
                placeHolderText={placeHolderText}
                pricingInfo={{
                    currency,
                    minimumFractionDigits: 2,
                    culture: locale,
                    priceDifference: listPrice as number,
                    priceDifferenceDiscounted: discountPrice
                }}
            />
            {!hideQuantity && quantity && ` ${localized.get("forQuantity", { quantity: quantity.toString() })}`}{" "}
            {!hideVatMessage && (
                <VatMessage
                    placeHolderText="..."
                    pricingInfo={{
                        culture: locale,
                        vatInc
                    }}
                />
            )}
        </P>
    );
});

DifferentialPricingDisplay.displayName = "DifferentialPricingDisplay";
