import type { LocalizationMessages } from "@internal/utils-i18n";

export interface LocalizedKeys {
    forQuantity: string;
    included: string;
}

export const localizationMessages: LocalizationMessages<LocalizedKeys> = {
    forQuantity: {
        id: "studio.differentialPricingComponent.forQuantity",
        defaultMessage: "for [[quantity]]",
        description: "This would be used like [cost] for [quantity], so $10 for 300 cards"
    },
    included: {
        id: "studio.differentialPricingComponent.included",
        defaultMessage: "Included"
    }
};
