import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { initPricingContextModule } from "@internal/data-access-pricing";
import { useEffect } from "react";

export function useInitializePricing(
    setPricingInitialized: Function,
    dispatch: Function,
    isIdentityInitialized: boolean
) {
    const { locale } = useProductAndProjectStateManager().data;

    useEffect(() => {
        if (isIdentityInitialized) {
            (async () => {
                await initPricingContextModule(locale);
                dispatch(setPricingInitialized());
            })();
        }
    }, [dispatch, isIdentityInitialized, locale, setPricingInitialized]);
}
