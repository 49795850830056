import React, { useContext, createContext, ReactNode, useMemo, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ERROR_CODES, StudioError, useErrors } from "@internal/utils-errors";
import { getDifferentialPricing, Pricing, formatPricingData } from "@internal/data-access-pricing";
import {
    type MappedProductOption,
    mapProductOptionsToDesignAttributes,
    UseStudioConfigurationManager
} from "@internal/utils-studio-configuration-core";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import type { ProductOption, CompatibleOptionsEntry } from "@internal/data-access-product";

type ContextData = {
    pricing: Pricing | undefined;
};

type Props = {
    children: ReactNode | ReactNode[];
    pricingInitialized: boolean;
    compatibleOptions: CompatibleOptionsEntry[] | undefined;
    designAttributeMappings: ProductOption[];
    useStudioConfigurationManager: UseStudioConfigurationManager;
};

const Context = createContext<ContextData | undefined>(undefined);

export const usePricing = () => {
    const result = useContext(Context);
    if (!result) {
        throw Error("Please call this within a PricingProvider");
    }
    return result;
};

const { Provider } = Context;

export const PricingProvider = observer((props: Props) => {
    const { children, useStudioConfigurationManager, pricingInitialized, compatibleOptions, designAttributeMappings } =
        props;
    const { productKey, studioSelectedProductOptions, quantity, productVersion } =
        useProductAndProjectStateManager().data;
    const { shouldShowCutline } = useStudioConfigurationManager().data;
    const [pricing, setPricing] = useState<Pricing | undefined>();
    const isPricingEnabled = shouldShowCutline;
    const { handleError } = useErrors();

    // create choice groups for all design attributes
    const choiceGroups = useMemo(() => {
        let choiceGroups: Record<string, Record<string, string>> | undefined;
        if (designAttributeMappings && compatibleOptions) {
            const productOptions = mapProductOptionsToDesignAttributes(designAttributeMappings, compatibleOptions);

            choiceGroups = productOptions.reduce(
                (accumulator: Record<string, Record<string, string>>, currentValue: MappedProductOption) => {
                    const { designAttributeName, designAttributeValue, productOptionName, productOptionValue } =
                        currentValue;
                    let choiceGroupKey: string;
                    if (designAttributeName) {
                        choiceGroupKey = `${designAttributeName}-${designAttributeValue}`;
                    } else {
                        choiceGroupKey = `${productOptionName}-${productOptionValue}`;
                    }
                    return { ...accumulator, [choiceGroupKey]: { [productOptionName]: productOptionValue } };
                },
                {}
            );
        }
        return choiceGroups;
    }, [designAttributeMappings, compatibleOptions]);

    // loadPricing again whenever studio selected value changes
    useEffect(() => {
        if (pricingInitialized && isPricingEnabled && choiceGroups && Object.keys(choiceGroups)?.length > 0) {
            (async () => {
                try {
                    if (productVersion === null) {
                        throw Error("Product version is not defined");
                    }
                    const pricingData = await getDifferentialPricing(
                        productKey,
                        studioSelectedProductOptions,
                        quantity,
                        choiceGroups ?? {},
                        productVersion
                    );

                    const formattedPricing = formatPricingData(pricingData);
                    setPricing(formattedPricing);
                } catch (e) {
                    setPricing(undefined);
                    handleError(e as StudioError, ERROR_CODES.FLEXIBILITY_PRICING, 10);
                }
            })();
        }
    }, [
        productKey,
        quantity,
        choiceGroups,
        productVersion,
        studioSelectedProductOptions,
        isPricingEnabled,
        pricingInitialized,
        handleError
    ]);

    const contextObject = useMemo(
        () => ({
            pricing
        }),
        [pricing]
    );

    return <Provider value={contextObject}>{children}</Provider>;
});

PricingProvider.displayName = "PricingProvider";
